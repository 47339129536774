<template>
  <div class="_page-content">
    <a-tabs defaultActiveKey="1" ref="tabs" size="small" tabPosition="top" type="line" :tabBarGutter=0 @change="callback">
      <a-tab-pane tab="顶部菜单" key="1">
        <permitssionTop :tab-key="tabKey"/>
      </a-tab-pane>
      <a-tab-pane tab="左侧菜单" key="2">
        <permitssionList :tab-key="tabKey"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import permitssionList from './permission-detail.vue'
import permitssionTop from './permitssionTop.vue'
export default {
  name: 'TestAxTable',
  data () {
    return {
      tabKey: ''
    }
  },
  components: {
    permitssionList,
    permitssionTop
  },
  methods: {
    callback (key) {
      this.tabKey = key
    }
  }
}
</script>
